import request from '@/utils/request'

// 智能分货列表
export function listProduceBillGoodsAuto(data) {
  return request({
    url: '/api/system/produce/bill/goods/auto/listProduceBillGoodsAuto',
    method: 'post',
    data
  })
}

// 更新单据明细信息
export function updateProductStorageDetail(data) {
  return request({
    url: '/api/system/produce/bill/storage/updateProductStorageDetail',
    method: 'post',
    data
  })
}

// 计算分货
export function calculateDistribute(data) {
  return request({
    url: '/api/system/smart/distribute/calculateDistribute',
    method: 'post',
    data
  })
}

// 获取计算的分货详情
export function getCalculateDistributeDetail(data) {
  return request({
    url: '/api/system/smart/distribute/getCalculateDistributeDetail',
    method: 'post',
    data
  })
}

// 修改实配数
export function modifyFactDistributeQty(data) {
  return request({
    url: '/api/system/smart/distribute/modifyFactDistributeQty',
    method: 'post',
    data
  })
}

// 完成配货
export function completeDistribute(data) {
  return request({
    url: '/api/system/smart/distribute/completeDistribute',
    method: 'post',
    data
  })
}

// 要货加单列表
export function listDistributeAddBill(data) {
  return request({
    url: '/api/system/smart/distribute/listDistributeAddBill',
    method: 'post',
    data
  })
}

// 添加要货加单
export function addDistributeObject(data) {
  return request({
    url: '/api/system/smart/distribute/addDistributeObject',
    method: 'post',
    data
  })
}

// 重置分货计算
export function resetDistribute(data) {
  return request({
    url: '/api/system/smart/distribute/resetDistribute',
    method: 'post',
    data
  })
}

// 多个计算的分货详情
export function listCalculateDistributeDetail(data) {
  return request({
    url: '/api/system/smart/distribute/listCalculateDistributeDetail',
    method: 'post',
    data
  })
}

// 源单列表
export function listSmartDistribute(data) {
  return request({
    url: '/api/system/smart/distribute/listSmartDistribute',
    method: 'post',
    data
  })
}

// 添加要分货的商品
export function addSmartDistributeGoods(data) {
  return request({
    url: '/api/system/produce/bill/goods/auto/addSmartDistributeGoods',
    method: 'post',
    data
  })
}

// 删除要分货的商品
export function delSmartDistributeGoods(data) {
  return request({
    url: '/api/system/produce/bill/goods/auto/delSmartDistributeGoods',
    method: 'post',
    data
  })
}

// 修改要分货商品的信息
export function modifySmartDistributeGoodsInfo(data) {
  return request({
    url: '/api/system/produce/bill/goods/auto/modifySmartDistributeGoodsInfo',
    method: 'post',
    data
  })
}

// 获取候选的分货对象
export function listDistributeObj(params) {
  return request({
    url: '/api/system/smart/distribute/listDistributeObj',
    method: 'get',
    params
  })
}

// 手动添加分货对象
export function artificiallyAddDistributeObject(data) {
  return request({
    url: '/api/system/smart/distribute/artificiallyAddDistributeObject',
    method: 'post',
    data
  })
}

// 手动删除分货对象
export function delDistributeObject(data) {
  return request({
    url: '/api/system/smart/distribute/delDistributeObject',
    method: 'post',
    data
  })
}