import request from '@/utils/request'

//
export const getOrderDetail = params =>
  request({
    url: '/api/system/decoration/base/man/getOrderDetail',
    method: 'get',
    params
  })

// 生成订单(需要先生成订单后再去调用支付二维码)
export const generateOrder = data =>
  request({
    url: '/api/system/decoration/base/man/generateOrder',
    method: 'post',
    data
  })

export const orderPay = data =>
  request({
    url: '/api/system/decoration/base/man/orderPay',
    method: 'post',
    data
  })

export const getManList = params =>
  request({
    url: '/api/system/decoration/base/man/list',
    method: 'GET',
    params
  })

export const getOrderState = params =>
  request({
    url: '/api/system/decoration/base/man/getOrderState',
    method: 'GET',
    params
  })

export const save = data =>
  request({ url: '/api/system/decoration/base/man/save', method: 'POST', data })

export const remove = data =>
  request({
    url: '/api/system/decoration/base/man/remove',
    method: 'DELETE',
    data
  })

export const update = data =>
  request({
    url: '/api/system/decoration/base/man/update',
    method: 'PUT',
    data
  })

// 裱花间分页列表
export const getRoomList = params =>
  request({ url: '/api/system/decoration/room/list', method: 'get', params })
// 现烤间分页列表
export const getFreshlyRoomList = params =>
  request({ url: '/api/system/freshly/baked/room/list', method: 'get', params })

// 获取裱花间详情
export const getRoomDetail = params =>
  request({
    url: '/api/system/decoration/room/getDetai',
    method: 'get',
    params
  })

// 删除裱花间
export const delRoom = data =>
  request({
    url: `/api/system/decoration/room/delete`,
    method: 'DELETE',
    data
  })
//还原裱花间
export function decorationRoomReduction (workerId) {
  return request({
    url: '/api/system/decoration/room/reduction',
    method: 'post',
    data: workerId
  })
}

// 新增裱花间
export const saveRoom = data =>
  request({ url: '/api/system/decoration/room/save', method: 'post', data })

// 修改裱花间
export const updateRoom = data =>
  request({ url: '/api/system/decoration/room/update', method: 'PUT', data })

// 导出裱花间分页列表
export const exportRoom = data =>
  request({ url: '/api/system/decoration/room/export', method: 'post', data })

// 裱花商品方案分页列表
export const getCaseList = params =>
  request({ url: '/api/system/decoration/case/list', method: 'get', params })

// 获取裱花间商品方案详情
export const getCaseDetail = params =>
  request({
    url: '/api/system/decoration/case/getDetail',
    method: 'get',
    params
  })

// 是否停用裱花商品方案
export const isStopCase = data =>
  request({ url: '/api/system/decoration/case/isStop', method: 'post', data })

// 删除裱花商品方案
export const delCase = data =>
  request({
    url: `/api/system/decoration/case/delete`,
    method: 'DELETE',
    data
  })

// 新增裱花商品方案
export const saveCase = data =>
  request({ url: '/api/system/decoration/case/save', method: 'post', data })

// 修改裱花商品方案
export const updateCase = data =>
  request({ url: '/api/system/decoration/case/update', method: 'PUT', data })

// 裱花裱花间订单分页列表
export const getRoomOrder = params =>
  request({
    url: '/api/system/decoration/room/order/list',
    method: 'get',
    params
  })

// 获取裱花间订单方案详情
export const getRoomOrderDetail = params =>
  request({
    url: '/api/system/decoration/room/order/getDetail',
    method: 'get',
    params
  })

// 导出裱花间订单
export const exportRoomOrder = data =>
  request({
    url: '/api/system/decoration/room/order/export',
    method: 'post',
    data
  })

// 删除裱花间订单
export const delRoomOrder = data =>
  request({
    url: `/api/system/decoration/room/order/delete/${data}`,
    method: 'DELETE'
  })

// 新增裱花间订单
export const saveRoomOrder = data =>
  request({
    url: '/api/system/decoration/room/order/save',
    method: 'post',
    data
  })

// 修改裱花间订单
export const updateRoomOrder = data =>
  request({
    url: '/api/system/decoration/room/order/update',
    method: 'PUT',
    data
  })

// 通过裱花间编号获取门店原料消耗分页列表
export const listStoreBillTranQuery = params =>
  request({
    url: '/api/system/decoration/room/order/listStoreBillTranQuery',
    method: 'GET',
    params
  })

// 将订单调至指定门店
export const deliveryShopUpdate = data =>
  request({
    url: '/api/system/decoration/room/delivery/deliveryShopUpdate',
    method: 'post',
    data
  })

// 获取订单详情(指定订单详情商品)
export const getOrderMiddleFroundOneInfo = params =>
  request({
    url: '/api/system/decoration/room/order/getOrderMiddleFroundOneInfo',
    method: 'GET',
    params
  })

// 将订单配送
export const deliveryShopOrder = data =>
  request({
    url: '/api/system/decoration/room/delivery/deliveryShopOrder',
    method: 'post',
    data
  })

// 根据门店 id 获取对应仓库
export const getShopAllStore = params =>
  request({
    url: '/api/system/decoration/room/delivery/getAllStore',
    method: 'get',
    params
  })

// 将订单改为制作中的订单
export const orderDoing = data =>
  request({
    url: '/api/system/decoration/room/delivery/deliveryShopProductionOrderUpdate',
    method: 'post',
    data
  })

// 制作中
export const orderDone = data =>
  request({
    url: '/api/system/decoration/room/delivery/deliveryShopProductionCompleteOrderUpdate',
    method: 'post',
    data
  })

// 打印
export const printOrder = data =>
  request({
    url: '/api/system/decoration/room/delivery/deliveryShopPrintOrder',
    method: 'post',
    data
  })

export const getPackageList = params =>
  request({ url: '/api/system/decoration/package/list', method: 'get', params })

export const getFreshlyList = params =>
  request({ url: '/api/system/freshly/baked/package/list', method: 'get', params })

// 裱花间生产分页列表
export const getProduceList = params =>
  request({
    url: '/api/system/decoration/bill/produce/list',
    method: 'GET',
    params
  })

// 裱花间生产分页详情
export const getProduceDetail = params =>
  request({
    url: '/api/system/decoration/bill/produce/getDetail',
    method: 'GET',
    params
  })

// 更新裱花间生产状态
export const updateBillStatus = data =>
  request({
    url: '/api/system/decoration/bill/produce/updateBillStatus',
    method: 'POST',
    data
  })

// 新增裱花生产
export const addOrder = data =>
  request({
    url: '/api/system/decoration/bill/produce/save',
    method: 'POST',
    data
  })

// 修改裱花生产
export const updateOrder = data =>
  request({
    url: '/api/system/decoration/bill/produce/update',
    method: 'PUT',
    data
  })

// 裱花间产品续费
export const tradeProductRenew = data =>
  request({
    url: '/api/system/saas/decoration/product/decorationProductRenew',
    method: 'post',
    data
  })

// 获取要续费的裱花间产品
export const getTradeProductToRenew = data =>
  request({
    url: '/api/system/saas/decoration/product/getDecorationToRenew',
    method: 'post',
    data
  })

// 裱花间产品订单分页列表
export const getTradeProductOrder = data =>
  request({
    url: '/api/system/saas/decoration/product/listDecorationOrder',
    method: 'get',
    data
  })

